// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgEyeRevealed = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    viewBox="0 0 24 25"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.316 13.808c-3.334-7.41-13.297-7.41-16.632 0a.75.75 0 0 1-1.368-.616c3.866-8.59 15.503-8.59 19.368 0a.75.75 0 1 1-1.368.616"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.75 14.5a3.75 3.75 0 1 0-7.5 0 3.75 3.75 0 0 0 7.5 0"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17 11.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17 3.5v3h3"
    />
  </svg>
);
export const EyeRevealedIcon = forwardRef(SvgEyeRevealed);
